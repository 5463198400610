import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar';
import AdminFooter from '../components/Footers/AdminFooter';
import Sidebar from '../components/Sidebar/Sidebar';
import { AuthContext } from '../context/AuthContext';

import { getRoutes } from '../routes';

const Admin = (props) => {
  const { loadUser, user } = useContext(AuthContext);
  const mainContent = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    loadUser();
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  // eslint-disable-next-line no-unused-vars
  const getBrandText = (path) => {
    // eslint-disable-next-line no-plusplus
    if (path == '/admin/fgts-customer-services/create') {
      if (props?.children?.props?.location?.search == '?isCdc=true') {
        return 'Nova Proposta FGTS CDC';
      }
    }

    if (path == '/admin/contracts/create') {
      console.log(props?.children?.props?.location?.search)
      if (props?.children?.props?.location?.search.includes('kind=fgts')) {
        return 'Contratos FGTS';
      }
    }

    if (path == '/admin/fgts-customer-services') {
      if (props?.children?.props?.location?.search == '?isCdc=true') {
        return 'Contratos CDC FGTS';
      }
    }

    for (let i = 0; i < getRoutes().length; i++) {
      if (path.indexOf(getRoutes()[i].layout + getRoutes()[i].path) !== -1) {
        return getRoutes()[i].name;
      }
    }

    if (path == '/admin/fgts-contracts') {
      if (props?.children?.props?.location?.search == '?isCdc=true') {
        return 'Contratos CDC FGTS';
      }

      return 'Contratos FGTS';
    } else {
      return 'Contratos';
    }
  };

  return (
    <>
      <Sidebar
        {...props.children}
        routes={getRoutes()}
        logo={{
          innerLink:
            user &&
            (user.role_default == 'user_store_register' ||
              user.role_default == 'user_corban_register' ||
              user.role_default == 'user_subest_register')
              ? '/admin/profile'
              : '/admin/fgts-contracts-uy3',
          imgSrc: user && user.logo_file ? user.logo_file : require('../assets/img/logo_novosaque.png').default,
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props.children}
          brandText={getBrandText(props.children.props.location.pathname)}
        />
        {props.children}
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
