import React, { useState, useContext, useEffect } from 'react';
import { Button, Row, Col, FormGroup } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { toastError, toastSuccess } from '../../util/toast';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';

const LogoSubstablishmentUpload = ({ subestablishment }) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [logo, setLogo] = useState([]);
  const [favicon, setFavicon] = useState([]);
  const [logoPersist, setLogoPersist] = useState('');
  const [faviconPersist, setFaviconPersist] = useState('');

  useEffect(() => {
    console.log(subestablishment)
    setLogoPersist(subestablishment.logo_file)
    setFaviconPersist(subestablishment.favicon_file)
  }, [])

  async function removeImagePersist(type) {
    try {
      await api.delete(`documents/${type}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (type === 'logo') {
        setLogoPersist(null);
      } else {
        setFaviconPersist(null);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function onSubmit() {
    if (
      logo.length === 0 &&
      favicon.length === 0 &&
      !logoPersist &&
      !faviconPersist
    ) {
      toastError('Selecione pelo menos uma imagem (logo ou favicon)');
      return;
    }

    showLoader();
    try {
      const formData = new FormData();

      if (logo.length > 0) {
        logo.forEach((item) => {
          formData.append('images[logo_file]', item);
        });
        formData.append('images[kind]', 'logo');
      }

      if (favicon.length > 0) {
        favicon.forEach((item) => {
          formData.append('images[favicon_file]', item);
        });
        formData.append('images[kind]', 'favicon');
      }

      const response = await api.put(`subestablishments/${subestablishment.id}/update_logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token()}`,
        },
      });

      console.log(response);
      hideLoader();
      toastSuccess('Imagens atualizadas com sucesso');
    } catch (error) {
      hideLoader();
      console.log(error);
      let message =
        error.response?.data?.message ||
        'Erro ao atualizar as imagens. Tente novamente!';
      toastError(message);
    }
  }

  const handleDrop = (acceptedFiles, setFunction) => {
    setFunction(
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      )
    );
  };

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } =
    useDropzone({
      accept: 'image/*',
      onDrop: (files) => {
        handleDrop(files, setLogo)
        setLogoPersist(null)
      },
    });

  const {
    getRootProps: getFaviconRootProps,
    getInputProps: getFaviconInputProps,
  } = useDropzone({
    accept: 'image/*',
    onDrop: (files) => {
      handleDrop(files, setFavicon)
      setFaviconPersist(null)
    },
  });

  return (
    <>
      <h6 className="heading-small text-muted mb-4">Upload de Imagens</h6>

      <Row>
        <Col lg="6">
          <FormGroup className="mt-4 ml-4">
            <div {...getLogoRootProps()}>
              <input {...getLogoInputProps()} />
              <p className="dropzoneFile">
                Arraste ou clique para selecionar o logo
              </p>
            </div>
          </FormGroup>

          <div className="mb-4">
            {logoPersist && (
              <Col lg="4" className="positionRelative">
                <img src={logoPersist} className="imagePreview" />
                <Button
                  color="danger"
                  className="closeButtonImage"
                  onClick={() => removeImagePersist('logo')}
                  close
                />
              </Col>
            )}
            {logo.map((item, index) => (
              <Col key={index} lg="4" className="positionRelative">
                <img src={item.preview} className="imagePreview" />
                <Button
                  color="danger"
                  className="closeButtonImage"
                  onClick={() => {
                    setLogo(logo.filter((_, i) => i !== index))
                    setLogoPersist(null)
                  }}
                  close
                />
              </Col>
            ))}
          </div>
        </Col>

        {/* <Col lg="6">
          <FormGroup className="mt-4 ml-4">
            <div {...getFaviconRootProps()}>
              <input {...getFaviconInputProps()} />
              <p className="dropzoneFile">
                Arraste ou clique para selecionar o favicon
              </p>
            </div>
          </FormGroup>

          <div className="mb-4">
            {faviconPersist && (
              <Col lg="4" className="positionRelative">
                <img src={faviconPersist} className="imagePreview" />
                <Button
                  color="danger"
                  className="closeButtonImage"
                  onClick={() =>
                    removeImagePersist('favicon')
                  }
                  close
                />
              </Col>
            )}
            {favicon.map((item, index) => (
              <Col key={index} lg="4" className="positionRelative">
                <img src={item.preview} className="imagePreview" />
                <Button
                  color="danger"
                  className="closeButtonImage"
                  onClick={() =>
                    setFavicon(favicon.filter((_, i) => i !== index))
                  }
                  close
                />
              </Col>
            ))}
          </div>
        </Col> */}
      </Row>

      <div className="text-left pb-4">
        <Button
          type="button"
          onClick={onSubmit}
          className="btn btn-success px-4"
        >
          <i className="fas fa-paper-plane" /> Enviar
        </Button>
      </div>
    </>
  );
};

export default LogoSubstablishmentUpload;
