import React, { useContext, useState, useEffect } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import CustomerForm from '../../components/CustomerForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'context/AuthContext';

const CreateCustomer = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const query = new URLSearchParams(useLocation().search);
  const [hasClientFields, setHasClientFields] = useState(true);
  const [customer, setCustomer] = useState({
    name: '',
    birth_date: '',
    cpf_cnpj: '',
    zip_code: '',
    street: '',
    kind_account: 'pix',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    kind_pix: ''
  });

  const [banks, setBanks] = useState([]);
  const { user } = useContext(AuthContext)

  useEffect(() => {
    async function getBanks() {
      showLoader();

      try {
        const response = await api.get(
          query.get('kind') && query.get('kind') == 'fgts'
            ? `banks?q[has_fgts_true]=1`
            : `banks?q[active_eq]=true`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        const values = response.data.map((value, index) => {
          return {
            value: `${value.code} - ${value.name}`,
            label: `${value.code} - ${value.name}`,
          };
        });

        setBanks(values);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os bancos.');
      }
    }

    getBanks();
  }, []);

  const isValidEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  const isValidCPF = (cpf) => /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(cpf);
  const isValidCPFNumber = (cpf) => /^\d{11}$/.test(cpf);
  const isValidPhoneNumber = (phone) => /^\d{11}$/.test(phone);
  const isValidPhone = (phone) => /^\d{11}$/.test(phone);
  async function onSubmit(data) {
    showLoader();
    if (
      customer.kind_account == 'pix'
    ) {
      if (!customer?.gender_customer?.toString()) {
        toastError('Selecione o sexo');
        hideLoader();
        return;
      }

      if (customer.kind_pix == 'email') {
        if (!isValidEmail(customer.pix)) {
          toastError('Campo Pix: Formato invalido');
          hideLoader();
          return;
        }

        const resultEmailPix = await api.post(
          '/customers/valid_email',
          JSON.stringify({ email: customer.pix }),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (resultEmailPix.data[0] != 1) {
          toastError('Campo Pix: ' + resultEmailPix.data[1]);
          hideLoader();
          return;
        }
      }

      if (customer.kind_pix == 'cpf_cnpj') {
        if (!isValidCPF(customer.pix) && !isValidCPFNumber(customer.pix)) {
          toastError('Campo Pix: Formato invalido');
          hideLoader();
          return;
        }
      }

      if (customer.kind_pix == 'phone') {
        if (!isValidPhoneNumber(customer.pix) && !isValidPhone(customer.pix)) {
          toastError('Campo Pix: Formato invalido');
          hideLoader();
          return;
        }
      }

      
    } else {
      if (!customer.bank_name) {
        toastError('Preencher campo Banco');
        hideLoader();
        return;
      }

      if (!query.get('isCdc')) {
        if (!customer.agency_account) {
          toastError('Preencher campo Agência');
          hideLoader();
          return;
        }

        if (!customer.number_account) {
          toastError('Preencher campo Conta');
          hideLoader();
          return;
        }

        if (!customer.account_digit) {
          toastError('Preencher campo Digito');
          hideLoader();
          return;
        }
      }
    }

    const resultEmail = await api.post(
      '/customers/valid_email',
      JSON.stringify({ email: customer.email }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    if (resultEmail.data[0] != 1) {
      toastError('Campo Email: ' + resultEmail.data[1]);
      hideLoader();
      return;
    }

    if (!isValidPhone(customer.mobile.replace(/\D/g, ''))) {
      toastError('Campo Celular: Formato invalido');
      hideLoader();
      return;
    }

    let bank_account_data = {};

    if (!query.get('isCdc')) {
      if (
        customer.kind_account == 'pix'
      ) {
        bank_account_data = {
          // kind_pix: query.get('kind') && query.get('kind') == 'fgts' ? 'cpf_cnpj' : customer.kind_pix,
          kind_pix: customer.kind_pix,
          // pix: query.get('kind') && query.get('kind') == 'fgts' ? customer.cpf_cnpj.replace(/\D/g, '') : customer.pix,
          pix: customer.pix,
          kind_account: customer.kind_account,
          month: customer.month ? customer.month : '',
          number_bank: query.get('kind') && query.get('kind') == 'fgts' ? (customer.bank_name ? customer.bank_name.split('-')[0] : '') : '',
          name_bank: query.get('kind') && query.get('kind') == 'fgts' ? (customer.bank_name ? customer.bank_name.split('-')[1] : '') : '',
        };
      } else {
        bank_account_data = {
          number_bank: (customer.bank_name ? customer.bank_name.split('-')[0] : ''),
          name_bank: (customer.bank_name ? customer.bank_name.split('-')[1] : ''),
          agency_account: customer.agency_account,
          agency_digit: customer.agency_digit,
          number_account: customer.number_account,
          account_digit: customer.account_digit,
          kind: customer.account_kind,
          kind_account: customer.kind_account || 'ted',
          month: customer.month ? customer.month : '',
        };
      }
    }

    data = {
      customer: {
        birth_date: customer.birth_date,
        email: customer.email,
        mobile: customer.mobile,
        rg: customer.rg,
        marital_status: customer.marital_status || 'married',
        gender_customer: customer.gender_customer,
        mother_name: customer.mother_name,
        father_name: customer.father_name,
        entity_attributes: {
          name: customer.name,
          cpf_cnpj: customer.cpf_cnpj.replace(/[^0-9]+/g, ''),
          address_attributes: {
            zip_code: customer.zip_code,
            street: customer.street,
            number: customer.number,
            district: customer.district,
            city: customer.city,
            state: customer.state,
            complement: customer.complement,
          },
        },
      },
    };

    if (!query.get('isCdc')) {
      data['customer']['entity_attributes']['bank_account_attributes'] =
        bank_account_data;
    }

    let params = '';
    if (query.get('customerServiceId')) {
      params = `?customer_service_id=${query.get('customerServiceId')}`;
    }

    try {
      const response = await api.post(
        `customers${params}`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      toastSuccess('Cliente criado com sucesso');

      if (query.get('customerServiceId')) {
        swal({
          title: 'Atendimento',
          text: 'Deseja realizar um novo contrato?',
          icon: 'success',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        }).then(async (next) => {
          if (next) {
            props.history.push(
              `/admin/contracts/create?customerServiceId=${query.get(
                'customerServiceId'
              )}&${query.get('kind') ? `kind=${query.get('kind')}` : ''}&${
                query.get('isCdc') ? `isCdc=${query.get('isCdc')}` : ''
              }`
            );
          } else {
            props.history.push('/admin/customers');
          }
        });
      } else {
        props.history.push('/admin/customers');
      }
    } catch (error) {
      hideLoader();


      let message = '';

      if (error.response) {
        console.log(error.response)
        if (error.response.data.error) {
          const responseErrors = error.response.data.error;
          responseErrors.forEach((e) => {
            // console.log(e.key);
            // console.log(e.model);
            console.log(e)
            if (e.key == 'Documento' && e.model == 'entity') {
              setError('cpf_cnpj', 'inválido');
            } else if (e.key == 'Nascimento' && e.model == 'Nascimento') {
              setError('birth_date', 'Idade inválida. (min. 18, max. 100)');
            } else {
              toastError(e.cliente);
            }
          });
        }
      } else {
        toastError('Há informações incorretas, por favor revise-as.');
      }
      window.scrollTo(0, 0);
    }
  }

  function parseDigit(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, 'x');
    } else {
      return value;
    }
  }

  function parseAccount(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, '');
    } else {
      return value;
    }
  }

  function isAgenciaBancariaValida(numeroAgencia) {
    // Define o padrão de validação (por exemplo, 4 dígitos)
    const padraoValidacao = /^\d{4}$/;

    // Verifica se o número da agência corresponde ao padrão
    return padraoValidacao.test(numeroAgencia);
  }

  function validarContaBancaria(accountNumber) {
    // Verifica se o número é uma string numérica.
    if (typeof accountNumber !== 'string' || !/^\d+$/.test(accountNumber)) {
      return false;
    }

    // Verifica se o número tem um comprimento mínimo.
    if (accountNumber.length < 5) {
      return false;
    }

    // Outras verificações personalizadas podem ser adicionadas aqui, com base nas regras do banco.

    // Se passar por todas as verificações, consideramos válido.
    return true;
  }

  function handleOnChange(e) {
    if (e.target.name == 'email') {
      if (e.target.value === '') {
        setError('email', 'Campo obrigatório');
        setCustomer({
          ...customer,
          ['email']: e.target.value,
        });
      } else if (!isAgenciaBancariaValida(e.target.value)) {
        setError('email', 'Email inválido');
        setCustomer({
          ...customer,
          ['email']: e.target.value,
        });
      } else {
        setError('email', '');
        setCustomer({
          ...customer,
          ['email']: e.target.value,
        });
      }
    }
    if (e.target.name == 'agency_account') {
      if (e.target.value === '') {
        setError('agency_account', 'Campo obrigatório');
        setCustomer({
          ...customer,
          ['agency_account']: e.target.value,
        });
      } else if (!isAgenciaBancariaValida(e.target.value)) {
        setError('agency_account', 'Número da agência inválido');
        setCustomer({
          ...customer,
          ['agency_account']: e.target.value,
        });
      } else {
        setError('agency_account', '');
        setCustomer({
          ...customer,
          ['agency_account']: e.target.value,
        });
      }
    } else if (e.target.name == 'agency_digit') {
      setCustomer({
        ...customer,
        ['agency_digit']: parseDigit(e.target.value),
      });
    } else if (e.target.name == 'account_digit') {
      if (e.target.value === '') {
        setError('account_digit', 'Campo obrigatório');
        setCustomer({
          ...customer,
          ['account_digit']: parseDigit(e.target.value),
        });
      } else {
        setError('account_digit', '');
        setCustomer({
          ...customer,
          ['account_digit']: parseDigit(e.target.value),
        });
      }
    } else if (e.target.name == 'number_account') {
      if (e.target.value === '') {
        setError('number_account', 'Campo obrigatório');
        setCustomer({
          ...customer,
          ['number_account']: parseAccount(e.target.value),
        });
      } else if (!validarContaBancaria(e.target.value)) {
        setError('number_account', 'Conta inválido');
        setCustomer({
          ...customer,
          ['number_account']: parseAccount(e.target.value),
        });
      } else {
        setError('number_account', '');
        setCustomer({
          ...customer,
          ['number_account']: parseAccount(e.target.value),
        });
      }
    } else if (e.target.name == 'cpf_cnpj') {
      if (e.target.value.length < 15) {
        let cpf_cnpj = e.target.value;
        if (cpf_cnpj.length >= 12) {
          cpf_cnpj = cpf_cnpj
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        } else {
          cpf_cnpj = cpf_cnpj.replace(/(\d{3})(\d)/, '$1.$2');
        }
        setCustomer({ ...customer, [e.target.name]: cpf_cnpj });
      }
    } else if (e.target.name == 'mobile') {
      if (e.target.value.length < 16) {
        let mobile = e.target.value;

        if (mobile.length > 10) {
          mobile = mobile
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
        } else {
          mobile = mobile.replace(/^(\d{2})(\d)/, '($1) $2');
        }
        setCustomer({ ...customer, [e.target.name]: mobile });
      }
    } else if (e.target.name == 'kind_pix') {
      if(e.target.value == 'cpf_cnpj') {
        let cpf_cnpj = customer.cpf_cnpj

        if(cpf_cnpj) {
          cpf_cnpj = cpf_cnpj.replace(/\D/g, '');

          setCustomer({ ...customer, pix: cpf_cnpj, kind_pix: e.target.value });
        } else {
          setCustomer({ ...customer, pix: '', kind_pix: e.target.value })
        }
      } else if (e.target.value == 'email') {
        setCustomer({ ...customer, pix: customer.email, kind_pix: e.target.value });
        // setCustomer({ ...customer, pix: '', kind_pix: e.target.value });
      } else if (e.target.value == 'phone') {
        let mobile = customer.mobile;

        if (mobile) {
          mobile = mobile.replace(/\D/g, '');
          setCustomer({ ...customer, pix: mobile, kind_pix: e.target.value })
          // setCustomer({ ...customer, pix: '', kind_pix: e.target.value })
        } else {
          setCustomer({ ...customer, pix: '', kind_pix: e.target.value })
        }
      } else if (e.target.value == 'random') {
        setCustomer({ ...customer, pix: '', kind_pix: '', kind_account: 'ted' })
      } else {
        setCustomer({ ...customer, pix: '', kind_pix: '' })
      }
    } else {
      setCustomer({ ...customer, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeBank(e) {
    setCustomer({ ...customer, bank_name: e.value });
  }

  function dataAddress({ street, district, city, state }) {
    setCustomer({
      ...customer,
      street: street,
      district: district,
      city: city,
      state: state,
    });
  }

  async function getAttendance(id) {
    showLoader();

    try {
      const response = await api.get(`simulations/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if(query.get('kind') && query.get('kind') == 'fgts') {
        let cpf_cnpj = response.data.cpf_cnpj.replace(/\D/g, '');

        setCustomer({
          ...customer,
          name: response.data.name,
          email: response.data.email,
          mobile: response.data.phone,
          cpf_cnpj: response.data.cpf_cnpj,
          pix: cpf_cnpj, 
          kind_pix: 'cpf_cnpj'
        });
      } else {
        setCustomer({
          ...customer,
          name: response.data.name,
          email: response.data.email,
          mobile: response.data.phone,
          cpf_cnpj: response.data.cpf_cnpj
        });
      }

      if (query.get('kind') && !response.data.value_client) {
        setHasClientFields(false);
      }

      console.log(response);
      hideLoader();
    } catch (error) {
      console.log(error);
      hideLoader();
    }
  }

  useEffect(() => {
    if (query.get('customerServiceId')) {
      getAttendance(query.get('customerServiceId'));
    }
  }, []);

  return (
    <>
      <CustomerHeader />
      <CustomerForm
        banks={banks}
        hasClientFields={hasClientFields}
        customer={customer}
        title="Criar Cliente"
        titleButton="Criar Cliente"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        dataAddress={dataAddress}
        register={register}
        hasFgts={query.get('kind') && query.get('kind') === 'fgts'}
        isSimplifiedPayment={user?.simplified_payment_enabled}
        handleSubmit={handleSubmit}
        handleOnChangeBank={handleOnChangeBank}
        errors={errors}
      />
    </>
  );
};

export default CreateCustomer;
