import { useState } from "react";
import { FormGroup } from "reactstrap";

const AutoCompleteInput = ({ label, handleClickItemList, fetchFn, ...props }) => {
  const [fieldValue, setFieldValue] = useState('')
  const [autocompleteData, setAutoCompleteData] = useState([])

  const handleChange = async (e) => {
    setFieldValue(e.target.value)

    if (!e.target.value.length) setAutoCompleteData([]);

    if (e.target.value.length > 4) {
      const response = await fetchFn(e.target.value);

      const values = response.data.map((value) => {
        return {
          id: value.id,
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
        };
      });

      setAutoCompleteData(values)
    }

  }

  return (
    <FormGroup>
      <label className="form-control-label" htmlFor={`${label}`}>
        {label}
      </label>
      <input
        type="text"
        className="form-control"
        id={props.id ?? ''}
        name={props.name ?? ''}
        placeholder={props.placeholder ?? ''}
        onChange={(e) => handleChange(e)}
        value={fieldValue}
        {...props}
      />
      <ul className="auto-complete-list">
        {autocompleteData &&
          autocompleteData.map((item) => {
            return (
              <li
                key={item.id}
                className="auto-complete-item-list"
                onClick={() => {
                  handleClickItemList(item);
                  setFieldValue(item.label)
                  setAutoCompleteData([]);
                }}
              >
                {item.value}
              </li>
            );
          })}
      </ul>
    </FormGroup>
  )
}

export default AutoCompleteInput;
