import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { CSVLink } from "react-csv";

import InputMask from 'react-input-mask';
import UserHeader from '../Headers/UserHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import CommissionProfile from './CommissionProfile'
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import axios from 'axios';
import { VisibilityByUserRoles } from '../../util/visibility';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

const EntityProfile = (props) => {
  const [entity, setEntity] = useState('');
  const [address, setAddress] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [commissions, setCommissions] = useState([]);
  const [banks, setBanks] = useState([]);
  const [commissionEntity, setCommissionEntity] = useState(null);
  const [contactFields, setContactFields] = useState([]);

  const { register, handleSubmit, errors } = useForm();
  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [exportList, setExportList] = useState([])

  const [csvDisabled, setCsvDisabled] = useState(false)
  const csvLinkRef = useRef(csvLinkRef)

  function handleClickContact() {
    setContactFields([ ...contactFields, { mobile: '', email: '', type_contact: '' }])
  }

  const getInstallmentValue = (rate, installments= 12) => {
    return (((1000 * ((Math.pow((1+(rate/100)),installments)) * (rate/100)) / (Math.pow((1+(rate/100)),installments)-1)).toFixed(2))/1000).toFixed(5)
  }

  async function export_commission() {
    setCsvDisabled(true)
    try {
      const response = await api.get(
        `users/export_commissions`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        })
      setExportList(response.data)
      csvLinkRef?.current?.link.click();
    } catch(e) {

    }
    setCsvDisabled(false)
  }


  async function handleSubmitEstablishment(event) {
    event.preventDefault();
    event.stopPropagation();

    console.log(entity)
    console.log(address)
    console.log(bankAccount)
    console.log(contactFields)

    if(!bankAccount.kind_pix){
      toastError("Selecione o tipo de chave pix")
      hideLoader();
      return
    }

    if(bankAccount.kind_pix != 'random' && !bankAccount.pix){
      toastError("Preencher campo pix")
      hideLoader();
      return
    }

    let data = {}

    if(address) {
      if(address.id) {
        data = {
          ...data,
          address_attributes: {
            id: address.id,
            zip_code: address.zip_code,
            street: address.street,
            number: address.number,
            district: address.district,
            city: address.city,
            state: address.state,
            complement: address.complement,
          }
        }
      }else {
        data = {
          ...data,
          address_attributes: {
            zip_code: address.zip_code,
            street: address.street,
            number: address.number,
            district: address.district,
            city: address.city,
            state: address.state,
            complement: address.complement,
          }
        }
      }
    }

    if(props.kind != "seller"){
      data = {
        ...data,
        contacts_attributes: [
          ...contactFields
        ]
      }

      if (bankAccount) {
        if(bankAccount.id) {
          data = {
            ...data,
            bank_account_attributes: {
              id: bankAccount.id,
              number_bank: bankAccount.bank_name.split('-')[0],
              name_bank: bankAccount.bank_name.split('-')[1],
              agency_account: bankAccount.agency_account,
              agency_digit: bankAccount.agency_digit,
              number_account: bankAccount.number_account,
              account_digit: bankAccount.account_digit,
              kind: bankAccount.account_kind,
              kind_pix: bankAccount.kind_pix,
              pix: bankAccount.pix,
            },
          }
        }else{
          data = {
            ...data,
            bank_account_attributes: {
              number_bank: bankAccount.bank_name.split('-')[0],
              name_bank: bankAccount.bank_name.split('-')[1],
              agency_account: bankAccount.agency_account,
              agency_digit: bankAccount.agency_digit,
              number_account: bankAccount.number_account,
              account_digit: bankAccount.account_digit,
              kind: bankAccount.account_kind,
              kind_pix: bankAccount.kind_pix,
              pix: bankAccount.pix,
            },
          }
        }
      }
    }

    console.log(data)

    try {
      await api.put(`entities/${entity.id}`, JSON.stringify({entity: data}), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Dados atualizados com sucesso');

    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response.data.errors) {
        message = error.response.data.errors;
        message.forEach((e) => {
          toastError(e.msg);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
      }

      toastError(message);
    }

    console.log(data)
  }

  async function getBanks() {
    showLoader()

    try {
      const response = await api.get(
        `banks?q[active_eq]=true`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value,index) => {
        return {
          value: `${value.code} - ${value.name}`,
          label: `${value.code} - ${value.name}`
        }
      })

      console.log(values)
      setBanks(values);

      hideLoader();

    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os bancos.');
    }
  }

  async function getEntity(){
    try {
      const response = await api.get(
        'users/get_entity',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      setEntity(response.data)
      if(props.kind != 'commercial_manager_internal' && props.kind != 'supervisor_commmercial_internal' && props.kind != 'intern_consultant' && props.kind != 'regional_manager' && props.kind != 'commercial_manager'){
        setAddress(response.data.address)
        setContactFields(response.data.contacts.map(item => {return {...item, _destroy: false}}))

        setBankAccount({
          id: response.data.bank_account && response.data.bank_account.id ? response.data.bank_account.id : null,
          bank_name: `${response.data.bank_account.number_bank} - ${response.data.bank_account.name_bank}`,
          kind_pix: response.data.bank_account.kind_pix,
          pix: response.data.bank_account.pix,
          account_kind: response.data.bank_account.kind,
          agency_account: response.data.bank_account.agency_account,
          agency_digit: response.data.bank_account.agency_digit,
          account_digit: response.data.bank_account.account_digit,
          number_account: response.data.bank_account.number_account,

        })

        setCommissions(response.data.commission_bases)
        if(response.data.kind == "subestablishment" || response.data.kind == "store" || response.data.kind == "seller") {
          console.log(response.data.commissions[0])
          setCommissionEntity(response.data.commissions[0])
        }
      }

    } catch (error) {
      console.log(error)
      toastError('Ops, não foi possível atualizar seus dados, por favor tente novamente');
    }
  }

  function handleOnChange(e) {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  }

  function handleOnChangeAddress(e) {
    setAddress({ ...address, [e.target.name]: e.target.value });
  }

  function handleOnChangeBankAccount(e) {
    setBankAccount({ ...bankAccount, [e.target.name]: e.target.value });
  }

  function handleOnChangeBank(e){
    setBankAccount({ ...bankAccount, 'bank_name': e.value })
  }

  useEffect(() => {
    getEntity()
    getBanks()
  }, [])

  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          setAddress({
            ...address,
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
        } else {
          setAddress({
            ...address,
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  function handleClickContactRemove(index){
    console.log(index)
    const values = [...contactFields];
    // values.splice(index, 1);
    values[index]._destroy = true
    setContactFields(values);
  }

  function handleOnChangeTypeContact(event, index) {
    const values = [...contactFields];
    console.log(index)
    console.log(values[index])
    if (event.target.value == "email") {
      values[index].type_contact = event.target.value;
    } else if (event.target.value == "mobile") {
      values[index].type_contact = event.target.value;
    }

    setContactFields(values);
  }

  function handleOnChangeContact(index, event) {
    const values = [...contactFields]
    if(event.target.name == "email_contact") {
      values[index]["email"] = event.target.value;
    } else if (event.target.name == "mobile_contact") {
      values[index]["mobile"] = event.target.value;
    }

    setContactFields(values)
  }

  function getPercentValue(value, total) {
    if(value == 0 || value == null) {
      return 0
    }
    if(total == 0 || total == null) {
      return 0
    }

    return ((value * total)/(100))
  }


  return (
    <>
      <Form onSubmit={handleSubmitEstablishment}>
        <h6 className="heading-small text-muted mb-4">
          Informações Adicionais
        </h6>
        <div className="pl-lg-4">
          {
            props.kind != 'seller' && props.kind != 'commercial_manager_internal' && props.kind != 'supervisor_commmercial_internal' && props.kind != 'intern_consultant' && props.kind != 'regional_manager' && props.kind != 'commercial_manager' && (
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Razão Social
                    </label>
                    <Input
                      type="text"
                      className="form-control-alternative"
                      id="social_reason"
                      name="social_reason"
                      disabled
                      placeholder="Razão Social"
                      value={entity.social_reason}
                      onChange={handleOnChange}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="email">
                      Nome Fantasia
                    </label>
                    <Input
                      type="text"
                      className="form-control-alternative"
                      id="name"
                      placeholder="Nome Fantasia"
                      name="name"
                      value={entity.name}
                      disabled
                      onChange={handleOnChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )
          }
          {
            props.kind != 'commercial_manager_internal' && props.kind != 'supervisor_commmercial_internal' && props.kind != 'intern_consultant' && props.kind != 'regional_manager' && props.kind != 'commercial_manager' && (
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="birth_date"
                    >
                      { props.kind != 'seller' ? "CNPJ" : "CPF" }
                    </label>
                    <Input
                      type="text"
                      className="form-control-alternative"
                      id="cpf_cnpj"
                      disabled
                      name="cpf_cnpj"
                      value={entity.cpf_cnpj}
                      onChange={handleOnChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

            )
          }
        </div>
        {props.kind != 'commercial_manager_internal' && props.kind != 'supervisor_commmercial_internal' && props.kind != 'intern_consultant' && props.kind != 'regional_manager' && props.kind != 'commercial_manager' && (
          <>
          <hr className="my-4" />
          {/* Address */}
          <h6 className="heading-small text-muted mb-4">
            Endereço
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="zip_code"
                  >
                    CEP
                  </label>
                  <InputMask
                    name="zip_code"
                    className="form-control form-control-alternative"
                    id="zip_code"
                    placeholder="CEP"
                    type="text"
                    mask="99999-999"
                    value={address && address.zip_code}
                    inputRef={register({ required: true })}
                    onChange={handleOnChangeAddress}
                    onBlur={handleBlurZipCode}
                  />
                  {errors.zip_code && (
                    <small className="text-danger">
                      O cep é obrigatório
                    </small>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="10">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="street"
                  >
                    Endereço
                  </label>
                  <input
                    name="street"
                    className="form-control form-control-alternative"
                    id="street"
                    placeholder="Endereço"
                    type="text"
                    value={address && address.street}
                    ref={register({ required: true })}
                    onChange={handleOnChangeAddress}
                  />
                  {errors.street && (
                    <small className="text-danger">
                      O endereço é obrigatório
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="number"
                  >
                    Número
                  </label>
                  <input
                    name="number"
                    className="form-control form-control-alternative"
                    id="number"
                    type="number"
                    value={address && address.number}
                    ref={register({ required: true })}
                    onChange={handleOnChangeAddress}
                  />
                  {errors.number && (
                    <small className="text-danger">
                      O número é obrigatório
                    </small>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="district"
                  >
                    Bairro
                  </label>
                  <input
                    name="district"
                    className="form-control form-control-alternative"
                    id="district"
                    placeholder="Bairro"
                    type="text"
                    value={address && address.district}
                    ref={register({ required: true })}
                    onChange={handleOnChangeAddress}
                  />
                  {errors.district && (
                    <small className="text-danger">
                      O bairro é obrigatório
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="city">
                    Cidade
                  </label>
                  <input
                    name="city"
                    className="form-control form-control-alternative"
                    id="city"
                    placeholder="Cidade"
                    type="text"
                    value={address && address.city}
                    ref={register({ required: true })}
                    onChange={handleOnChangeAddress}
                  />
                  {errors.city && (
                    <small className="text-danger">
                      A cidade é obrigatório
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="state">
                    Estado
                  </label>
                  <input
                    name="state"
                    className="form-control form-control-alternative"
                    id="state"
                    placeholder="Estado"
                    type="text"
                    value={address && address.state}
                    ref={register({ required: true })}
                    onChange={handleOnChangeAddress}
                  />
                  {errors.state && (
                    <small className="text-danger">
                      O estado é obrigatório
                    </small>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="8">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="complement"
                  >
                    Complemento
                  </label>
                  <input
                    name="complement"
                    className="form-control form-control-alternative"
                    id="complement"
                    placeholder="Complemento"
                    type="text"
                    value={address && address.complement}
                    ref={register({ required: false })}
                    onChange={handleOnChangeAddress}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          {
              props.kind != 'seller' && (
                <>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Dados Bancários
                </h6>
                <div className="pl-lg-4">

                  <Row>
                    <Col lg={"2 mb-4"}>
                      <label
                        className="form-control-label"
                        htmlFor="kind_pix"
                      >
                        Tipo Pix
                      </label>
                      <Input
                        type="select"
                        id="kind_pix"
                        disabled
                        name="kind_pix"
                        value={bankAccount && bankAccount.kind_pix}
                        onChange={handleOnChangeBankAccount} >
                        <option>Selecione o tipo</option>
                        <option value="cpf_cnpj">CPF/CNPJ</option>
                        <option value="email">E-mail</option>
                        <option value="phone">Telefone</option>
                        <option value="random">{"Dados Bancários (conforme abaixo)"}</option>
                      </Input>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="pix"
                        >
                          Pix
                        </label>
                        <InputMask
                          type="text"
                          name="pix"
                          className="form-control form-control-alternative"
                          id="pix"
                          disabled
                          placeholder="Pix"
                          value={bankAccount && bankAccount.pix}
                          // inputRef={register({ required: pixRequired })}
                          onChange={handleOnChangeBankAccount}
                        />
                          {errors.pix && (
                            <small className="text-danger">
                              Pix é obrigatório
                            </small>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="agency_number"
                    >
                      Banco
                    </label>
                      <FormGroup>
                      <Input
                        className="basic-single"
                        classNamePrefix="select"
                        name="bank_name"
                        
                        disabled={true}
                        // placeholder="Selecione o banco"
                        value={`${bankAccount.bank_name}`}
                        options={[]}
                        readOnly
                        onChange={handleOnChangeBank}
                      />
                      {errors.agency_account && (
                        <small className="text-danger">
                          O banco é obrigatório
                        </small>
                      )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="agency_account"
                        >
                          Agência
                        </label>
                        <InputMask
                          type="text"
                          name="agency_account"
                          className="form-control form-control-alternative"
                          id="agency_account"
                          disabled
                          // placeholder="9999"
                          mask="9999"
                          maskChar=""
                          value={bankAccount && bankAccount.agency_account}
                          inputRef={register({ required: true })}
                          onChange={handleOnChangeBankAccount}
                        />
                        {errors.agency_account && (
                          <small className="text-danger">
                            A agência é obrigatório
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="2">
                      {/* <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="agency_digit"
                        >
                          Dígito
                        </label>
                        <InputMask
                          type="text"
                          name="agency_digit"
                          className="form-control form-control-alternative"
                          id="agency_digit"
                          // placeholder="9"
                          mask="*"
                          maskChar=""
                          value={bankAccount && bankAccount.agency_digit}
                          inputRef={register({ required: false })}
                          onChange={handleOnChangeBankAccount}
                        />
                        {errors.agency_digit && (
                          <small className="text-danger">
                            O digito da agência é obrigatório
                          </small>
                        )}
                      </FormGroup> */}
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="number_account"
                        >
                          Conta
                        </label>
                        <InputMask
                          disabled
                          type="text"
                          name="number_account"
                          className="form-control form-control-alternative"
                          id="number_account"
                          // mask="9999999"
                          // maskChar=""
                          value={bankAccount && bankAccount.number_account}
                          inputRef={register({ required: true })}
                          onChange={handleOnChangeBankAccount}
                        />
                        {errors.number_account && (
                          <small className="text-danger">
                            A conta é obrigatório
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="account_digit"
                        >
                          Dígito
                        </label>
                        <InputMask
                          disabled
                          type="text"
                          name="account_digit"
                          className="form-control form-control-alternative"
                          id="account_digit"
                          // placeholder="9"
                          mask="*"
                          maskChar=""
                          value={bankAccount && bankAccount.account_digit}
                          inputRef={register({ required: true })}
                          onChange={handleOnChangeBankAccount}
                        />
                        {errors.account_digit && (
                          <small className="text-danger">
                            O digito da conta é obrigatório
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="2  mb-4">
                      <Label for="account_kind">Tipo de conta</Label>
                      <Input
                        type="select"
                        id="account_kind"
                        name="account_kind"
                        disabled
                        value={bankAccount && bankAccount.account_kind}
                        onChange={handleOnChangeBankAccount} >
                        <option>Selecione o tipo</option>
                        <option value="0">Conta Corrente</option>
                        <option value="1">Poupança</option>
                      </Input>
                    </Col>
                  </Row>
                </div>

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Contatos
                </h6>
                <div className="pl-lg-4">
                  { contactFields.map((contactField, index) => (

                    !contactField._destroy && (
                    <div key={index}>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <Label for="exampleselect">Escolha o tipo de contato</Label>
                            <Input type="select" value={contactField.type_contact} name="select" id="exampleselect" onChange={(event) => handleOnChangeTypeContact(event, index)} >
                              <option>Selecione o tipo</option>
                              <option value="email">Email</option>
                              <option value="mobile">Celular</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        {
                          contactField.type_contact == "mobile" ?
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="mobile_contact">
                                Celular
                              </label>
                              <InputMask
                                type="text"
                                name="mobile_contact"
                                className="form-control form-control-alternative"
                                id="mobile_contact"
                                placeholder="(99) 99999-9999"
                                mask="(99) 99999-9999"
                                value={contactField && contactField.mobile}
                                inputRef={register({ required: true })}
                                onChange={event => handleOnChangeContact(index, event)}
                              />
                              {errors.mobile_contact && (
                                <small className="text-danger">
                                  Celular é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          :
                          contactField.type_contact == "email" ?
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="email_contact">
                                Email
                              </label>
                              <input
                                type="email_contact"
                                name="email_contact"
                                className="form-control form-control-alternative"
                                id="email_contact"
                                value={contactField && contactField.email}
                                placeholder="example@example.com"
                                ref={register({ required: true })}
                                onChange={event => handleOnChangeContact(index, event)}
                              />
                              {errors.email_contact && (
                                <small className="text-danger">
                                O email é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          :
                          false
                        }
                          <div className="mt-5">
                            <Button color="danger"
                              onClick={() => handleClickContactRemove(index)}
                            close />
                          </div>
                      </Row>
                    </div>
                    )

                  ))}
                  <br></br>
                  <Button color="primary"
                          onClick={handleClickContact}
                  >
                    Adicionar Contato
                  </Button>
                </div>
                </>
              )
          }
          {/* <CommissionProfile kind={props.kind} commissionEntity={commissionEntity} commissions={commissions}/> */}
          <div className="text-right">
            <Button type="submit" className="btn btn-success px-4">
              <i className="fas fa-paper-plane" /> Enviar
            </Button>
          </div>
          </>
        )}

      </Form>

    </>
  )

}

export default EntityProfile
